export enum AnswerAliases {
  NEVER = 'never',
  SELDOM = 'seldom',
  VERY_RARELY = 'very-rarely',
  RARELY = 'rarely',
  SOMETIMES = 'sometimes',
  HALF = 'half',
  FREQUENTLY = 'frequesntly',
  OFTEN = 'often',
  ALMOST_ALWAYS = 'almost-always',
  ALWAYS = 'always',
  CANT_ANSWER = 'cantAnswer',
}

export interface QuestionScaleAnswerIconData {
  // Код иконки для шкалы оценки
  icon: string;
  // Код иконки для индикатора оценки в аналитике
  iconGrade: string;
  // Цвет иконки для шкалы оценки
  color: string;
  // Приглушенный цвет (для тепловой карты)
  mutedColorCode: string;
}

export const QUESTION_SCALE_ANSWER_ICONS: Record<AnswerAliases, QuestionScaleAnswerIconData> = {
  [AnswerAliases.NEVER]: {
    icon: 'fa-face-dizzy',
    iconGrade: 'fa-face-dizzy',
    color: '#E5453F',
    mutedColorCode: '#b8514d',
  },
  [AnswerAliases.SELDOM]: {
    icon: 'fa-face-tired',
    iconGrade: 'fa-face-tired',
    color: '#F06A34',
    mutedColorCode: '#c26844',
  },
  [AnswerAliases.VERY_RARELY]: {
    icon: 'fa-face-angry',
    iconGrade: 'fa-face-angry',
    color: '#F8832B',
    mutedColorCode: '#db7d37',
  },
  [AnswerAliases.RARELY]: {
    icon: 'fa-face-frown-open',
    iconGrade: 'fa-face-frown-open',
    color: '#FF8D00',
    mutedColorCode: '#c9822a',
  },
  [AnswerAliases.SOMETIMES]: {
    icon: 'fa-face-frown',
    iconGrade: 'fa-face-frown',
    color: '#FFA900',
    mutedColorCode: '#c29234',
  },
  [AnswerAliases.HALF]: {
    icon: 'fa-smile',
    iconGrade: 'fa-smile',
    color: '#FFBF00',
    mutedColorCode: '#bd9b35',
  },
  [AnswerAliases.FREQUENTLY]: {
    icon: 'fa-face-smile-wink',
    iconGrade: 'fa-face-smile-wink',
    color: '#B2B400',
    mutedColorCode: '#9c9122',
  },
  [AnswerAliases.OFTEN]: {
    icon: 'fa-face-grin-wide',
    iconGrade: 'fa-face-grin-wide',
    color: '#929801',
    mutedColorCode: '#7e8f1b',
  },
  [AnswerAliases.ALMOST_ALWAYS]: {
    icon: 'fa-face-laugh',
    iconGrade: 'fa-face-laugh',
    color: '#6A8D24',
    mutedColorCode: '#607d23',
  },
  [AnswerAliases.ALWAYS]: {
    icon: 'fa-face-grin-stars',
    iconGrade: 'fa-face-grin-stars',
    color: '#327E31',
    mutedColorCode: '#536e52',
  },
  [AnswerAliases.CANT_ANSWER]: {
    icon: 'fa-times',
    iconGrade: 'fa-times',
    color: 'tt-light-red',
    mutedColorCode: '#EBECED',
  },
};
